<template>
  <div id="user-list">
    <div class="each-user" v-for="result in results.results" :key="result.name">
      <span class="round-img">
        <img :src="result.picture.medium" alt="All Users" />
      </span>
      <div id="user-details">
        <h4 id="name">
          {{ result.name.first + " " + result.name.last }}
        </h4>
        <p id="address">
          {{
            result.location.street.number +
              " " +
              result.location.street.name +
              ", " +
              result.location.city +
              ", " +
              result.location.state
          }}
        </p>
        <div class="contact">
          <div class="email-div">
            <img :src="require(`@/assets/mail.svg`)" alt="Email" />
            <p id="email">{{ result.email }}</p>
          </div>
          <div class="phone-div">
            <img :src="require(`@/assets/phone-call.svg`)" alt="Phone Number" />
            <p id="phone">{{ result.phone }}</p>
          </div>
          <div class="next-arrow">
            <img
              :src="require(`@/assets/arrow-right-solid.svg`)"
              alt="Next view"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-div">
      <a :href="url + '&page=' + page + '&format=csv&dl'">
        <img :src="require(`@/assets/ios-cloud-download.svg`)" alt="Download" />
        <span>Download results</span>
      </a>
      <div class="pagination">
        <div class="prev-page" v-on:click="prevpage">
          <img
            :src="require(`@/assets/angle-left-solid.svg`)"
            alt="Previous page"
          />
        </div>
        <div class="next-page" v-on:click="nextpage">
          <img
            :src="require(`@/assets/angle-right-solid.svg`)"
            alt="Next page"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const url =
  "https://randomuser.me/api/?gender=male&results=3&inc=gender,name,location,email,picture,phone";
export default {
  name: "MaleUsersList",
  data: function() {
    return {
      results: {
        results: [
          {
            gender: "male",
            name: {
              title: "Mr",
              first: "Flenn",
              last: "Howell"
            },
            location: {
              street: {
                number: 6834,
                name: "Woodland St"
              },
              city: "Torrance",
              state: "Virginia",
              country: "United States",
              postcode: 61897,
              coordinates: {
                latitude: "86.0804",
                longitude: "-58.8354"
              },
              timezone: {
                offset: "-10:00",
                description: "Hawaii"
              }
            },
            email: "flenn.howell@example.com",
            phone: "(297)-587-9396",
            picture: {
              large: "https://randomuser.me/api/portraits/men/41.jpg",
              medium: "https://randomuser.me/api/portraits/med/men/41.jpg",
              thumbnail: "https://randomuser.me/api/portraits/thumb/men/41.jpg"
            }
          },
          {
            gender: "male",
            name: {
              title: "Mr",
              first: "Armindo",
              last: "Barbosa"
            },
            location: {
              street: {
                number: 4443,
                name: "Rua Amazonas "
              },
              city: "Colatina",
              state: "Amapá",
              country: "Brazil",
              postcode: 40220,
              coordinates: {
                latitude: "26.9067",
                longitude: "140.5927"
              },
              timezone: {
                offset: "-7:00",
                description: "Mountain Time (US & Canada)"
              }
            },
            email: "armindo.barbosa@example.com",
            phone: "(61) 8931-5566",
            picture: {
              large: "https://randomuser.me/api/portraits/men/35.jpg",
              medium: "https://randomuser.me/api/portraits/med/men/35.jpg",
              thumbnail: "https://randomuser.me/api/portraits/thumb/men/35.jpg"
            }
          },
          {
            gender: "male",
            name: {
              title: "Mr",
              first: "James",
              last: "Walker"
            },
            location: {
              street: {
                number: 5440,
                name: "Wairakei Road"
              },
              city: "Palmerston North",
              state: "Southland",
              country: "New Zealand",
              postcode: 21683,
              coordinates: {
                latitude: "-62.0876",
                longitude: "93.2817"
              },
              timezone: {
                offset: "-9:00",
                description: "Alaska"
              }
            },
            email: "james.walker@example.com",
            phone: "(580)-447-4394",
            picture: {
              large: "https://randomuser.me/api/portraits/men/13.jpg",
              medium: "https://randomuser.me/api/portraits/med/men/13.jpg",
              thumbnail: "https://randomuser.me/api/portraits/thumb/men/13.jpg"
            }
          }
        ],
        info: {
          seed: "aaf8325b3d839348",
          results: 3,
          page: 1,
          version: "1.3"
        }
      },
      url,
      page: 1
    };
  },
  // mounted() {
  //   axios.get(url).then(response => {
  //     self.results = response.data;
  //   });
  // }

  beforeMount() {
    this.generate();
  },
  methods: {
    generate() {
      axios
        .get(url, {
          dataType: "json",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          mode: "no-cors",
          credentials: "include"
        })
        .then(response => {
          this.results = response.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    nextpage: function() {
      this.page += 1;
      axios.get(url + "&page=" + this.page).then(response => {
        this.results = response.data;
      });
    },
    prevpage: function() {
      if (this.page > 1) {
        this.page -= 1;
        axios.get(url + "&page=" + this.page).then(response => {
          this.results = response.data;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.each-user {
  display: flex;
  background: #ffffff;
  padding: 20px;
  flex-direction: row;
  border-radius: 1.5rem;
  margin-bottom: 1.5rem;
  .round-img img {
    border-radius: 50%;
    border: 5px solid #75d6d1;
    width: 6rem;
    height: auto;
  }
}
#user-details {
  margin: 0 2rem;
  #name {
    margin: 0;
    color: #262a41;
    font-size: 1.6rem;
    letter-spacing: -0.02px;
  }
  #address {
    font-size: 1.1rem;
    font-stretch: medium;
    font-style: italic;
    letter-spacing: -0.02px;
    color: #262a41;
    opacity: 0.69;
    margin: 5px 0;
  }
}
.contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  .email-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem;
  }
  .phone-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 5rem;
  }
  .email-div img,
  .phone-div img {
    width: auto;
    height: 1.5rem;
    margin-right: 0.5rem;
  }
  #email,
  #phone {
    font-size: 1.1rem;
    font-weight: 300;
    letter-spacing: -0.02px;
    color: #262a41;
    opacity: 0.52;
    margin: 5px 0;
  }
  .next-arrow img {
    width: auto;
    height: 1.5rem;
  }
  .next-arrow {
    background: #30bbb5;
    box-shadow: 2px 15px 30px #30bbb574;
    border-radius: 1rem;
    padding: 1rem;
    cursor: pointer;
  }
}
.bottom-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  a {
    text-decoration: none;
    color: #ffffff;
    font-size: 1rem;
    background: #7946c1 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    img {
      margin-right: 1rem;
      height: 2rem;
      width: auto;
    }
  }
  .prev-page,
  .next-page {
    border-radius: 1rem;
    padding: 1rem;
    box-shadow: 4px 5px 20px #0000001a;
    cursor: pointer;
    img {
      height: 1rem;
    }
  }
  .prev-page {
    margin-right: 1rem;
    background: #e2e2ea;
  }
  .next-page {
    background: #262a41;
  }
  .pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media only screen and (max-width: 768px) {
  #user-list {
    width: fit-content;
  }
  .each-user {
    padding: 20px 10px;
    .round-img {
      width: 20%;
      img {
        width: 75%;
        height: auto;
      }
    }
    #user-details {
      margin: 0 0 0 1rem;
      width: 70%;
      #name {
        font-size: 2.5em;
      }
      #address {
        font-size: 1.8em;
        margin: 5px 0;
      }
      .contact {
        display: flex;
        flex-flow: wrap;
        .email-div {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-right: 2rem;
        }
        .phone-div {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-right: 2rem;
        }
        .email-div img,
        .phone-div img {
          width: auto;
          height: 1.5rem;
          margin-right: 0.5rem;
        }
        #email,
        #phone {
          font-size: 1.1rem;
          font-weight: 300;
          letter-spacing: -0.02px;
          color: #262a41;
          opacity: 0.52;
          margin: 5px 0;
        }
        .next-arrow img {
          width: auto;
          height: 1.25rem;
        }
        .next-arrow {
          background: #30bbb5;
          box-shadow: 2px 15px 30px #30bbb574;
          border-radius: 1rem;
          padding: 1.5em;
          cursor: pointer;
        }
      }
    }
  }
  .bottom-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .prev-page,
    .next-page {
      border-radius: 1rem;
      padding: 1rem;
      box-shadow: 4px 5px 20px #0000001a;
      cursor: pointer;
      img {
        height: 1rem;
      }
    }
    .prev-page {
      margin-right: 1rem;
      background: #e2e2ea;
    }
    .next-page {
      background: #262a41;
    }
    .pagination {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
</style>
