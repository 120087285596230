<template>
  <div id="user-container">
    <h1 id="user-type">Female Users</h1>
    <form id="filter-form" action="" method="GET">
      <p>Filter by</p>
      <div class="input-row">
        <input type="search" placeholder="Find in list" />
        <select id="country-list">
          <option value="">Country</option>
        </select>
        <label class="checkbox" for="show-country">
          <input type="checkbox" name="Show Country" id="show-country" />
          Show Country
        </label>
      </div>
    </form>
    <div class="user-list">
      <FemaleUsersList />
    </div>
  </div>
</template>

<script>
import FemaleUsersList from "@/components/FemaleUsersList.vue";

export default {
  name: "FemaleUsers",
  components: {
    FemaleUsersList
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#user-container {
  color: #000000;
  // top: 20px;
  width: fit-content;
  height: fit-content;
  background: #f7f7ff;
  border: 1px solid #707070;
  border-radius: 28px;
  padding: 3vh 5vw;
  margin: 2rem 0;
}
#user-type {
  font-size: 3rem;
  font-weight: bold;
  letter-spacing: -0.04px;
  color: #262a41;
  padding-bottom: 1rem;
}
form input,
form select {
  border-radius: 2rem;
  margin: 0.5rem 3rem 0 0;
  padding: 1rem;
  box-sizing: border-box;
  border: 1px solid #0000001a;
  background: #00000010;
}
input[type="search"]:focus,
form select:focus {
  outline: 0;
}
form {
  color: #000000;
  p {
    font-size: 1rem;
  }
}
.input-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2.5rem;
  // justify-content: space-evenly;
  .checkbox {
    font-size: 1rem;
    display: flex;
    align-items: center;
  }
  #show-country {
    margin: 0;
  }
}
@media only screen and (max-width: 768px) {
  #user-container {
    // top: 20px;
    width: fit-content;
    min-height: fit-content;
    border-radius: 20px;
    padding: 2vh auto;
    margin: 8rem 0 2rem 0;
  }
  #user-type {
    font-size: 2.5rem;
  }
  form {
    input,
    select {
      margin: 0.5rem 1.5rem 0 0;
      width: 30%;
      padding: 0.8rem;
    }
    p {
      font-size: 1rem;
    }
    .checkbox {
      margin: 0.5rem 1.5rem 0 0;
      width: 30%;
    }
  }
}
</style>
