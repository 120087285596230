<template>
  <div id="index">
    <div class="main-greeting">Hello, <span id="username">Emerald</span></div>
    <form action="">
      <p>
        Welcome to your dashboard, kindly sort through the user base
      </p>
      <input
        type="search"
        id="search-user"
        placeholder="Find a user"
        results="5"
      />
    </form>
    <h5>Show Users</h5>
    <div class="user-button">
      <router-link id="all-users" :to="{ name: 'Home' }">
        <span class="btn">
          <img :src="require(`@/assets/users-solid1.svg`)" alt="All Users"
        /></span>
        <span class="text">All Users</span>
      </router-link>
      <router-link id="male-users" :to="{ name: 'MaleUsersPage' }">
        <span class="btn">
          <img :src="require(`@/assets/male-solid1.svg`)" alt="Male Users" />
        </span>
        <span class="text">Male Users</span>
      </router-link>
      <router-link id="female-users" :to="{ name: 'FemaleUsersPage' }">
        <span class="btn">
          <img
            :src="require(`@/assets/female-solid1.svg`)"
            alt="Female Users"
          />
        </span>
        <span class="text">Female Users</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Index"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
#index {
  margin: auto auto;
  h5 {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 79px;
    letter-spacing: -0.02px;
    opacity: 0.7;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }
}
h3 {
  margin: 40px 0 0;
}
.main-greeting {
  font-weight: 200;
  font-size: 49px;
  line-height: 120px;
  letter-spacing: -0.05px;
}
#username {
  font-weight: 400;
  font-size: 37px;
  line-height: 122px;
  letter-spacing: -0.04px;
}
form p {
  font-weight: 300;
  font-size: 1.1rem;
  line-height: 1.5rem;
  letter-spacing: 0.23px;
  opacity: 0.66;
  margin-top: 1.6rem;
}
form input {
  margin: 1rem 0;
  padding: 1.7rem 2.8rem;
  border: none;
  box-sizing: border-box;
  width: 100%;
  border-radius: 1.2rem;
  background: #3c3f54;
  font-size: 1.5rem;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.7;
}
form input:focus {
  outline: 0;
}
#all-users img {
  width: 4rem;
  height: 3rem;
  margin: 1.25rem 0.5rem;
}
#male-users img {
  width: 1.5rem;
  height: auto;
  margin: 0.75rem 1.5rem;
}
#female-users img {
  width: auto;
  height: 4rem;
  margin: 0.75rem 1.4rem;
}
.user-button {
  display: flex;
  flex-direction: row;
  .text {
    font-size: 1.1rem;
  }
}
.btn {
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  border-radius: 1.5rem;
}
#all-users,
#male-users,
#female-users {
  text-align: center;
  margin-right: 1.5rem;
}
#all-users .btn {
  background: #f935a9;
}
#male-users .btn {
  background: #30bbb5;
}
#female-users .btn {
  background: #7946c1;
}
a {
  text-decoration: none;
  color: #ffffff;
}
</style>
