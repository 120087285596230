<template>
  <div class="home">
    <!-- <img :src="require(`@/assets/logo.png`)" alt="Vue logo" /> -->
    <div class="my-index">
      <Index />
    </div>
    <div class="male-users">
      <MaleUsers />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Index from "@/components/Index.vue";
import MaleUsers from "@/components/MaleUsers.vue";

export default {
  name: "MaleUsersPage",
  components: {
    Index,
    MaleUsers
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
.home {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: fit-content;
  margin: 0 auto;
  .my-index {
    display: flex;
    align-items: center;
    width: 50vw;
    background: #262a41;
    // overflow: hidden;
    position: relative;
  }
  .male-users {
    display: flex;
    width: fit-content;
    position: relative;
    // overflow-x: hidden;
    background: #262a41;
    margin-right: 50px;
  }
}
@media only screen and (max-width: 768px) {
  .home {
    flex-direction: column;
    padding: 5vw;
    .my-index {
      height: fit-content;
      width: fit-content;
      justify-content: center;
    }
    .male-users {
      height: fit-content;
      width: fit-content;
      margin: 0;
    }
  }
}
</style>
